const config = {
  env: "dev",
  local: {
    url: "http://localhost:8080/admin/api/",
  },
  dev: {
    url: "https://bbf.dev.pannovate.net/admin/api/",
  },
  stage: {
    url: "https://bbf.stage.pannovate.net/admin/api/",
  },
  live: {
    url: "https://bbf.pannovate.net/admin/api/",
  },
};

export default config;
